import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-black pb-12">
				<Header type="promoover" />
				<div className="bg-norepeat  relative z-[1200] w-full bg-black bg-cover bg-center bg-repeat-y pt-[0px] lg:h-[500px] lg:pt-[0px]">
					<div className=" mx-auto max-w-[1400px] pt-12 text-center text-white lg:pt-6">
						<h3 className="mb-12 text-[24px]">VIELEN DANK FÜR DIE TEILNAHME AN UNSEREM GEWINNSPIEL.</h3>
						<p className="mb-12 px-4 md:px-0">
							Deine Teilnahme ist erfolgreich bei uns eingegangen. <br />
							Deinen 25%-Gutschein für den Wilkinson Online-Shop erhältst du per E-Mail.
						</p>
						<p className="mb-12 px-4 md:px-0">
							Die Ermittlung der Gewinner erfolgt innerhalb von zwei Wochen nach Aktionsende per Zufallsziehung aus allen gültigen Teilnahmen.
							<br />
							Die Gewinner werden per E-Mail über ihren Gewinn informiert. Solltest du nach Ablauf des Zeitraums keine E-Mail von uns erhalten haben, bist du leider nicht unter den
							Gewinnern.
						</p>
						<p className="mb-12 px-4 md:px-0 lg:mb-14">
							Wir wünschen dir viel Glück.
							<br />
							Dein Wilkinson Sword Team
						</p>
						<div className="mb-12 pb-12">
							<Link to="/" className="btn font-Veener mt-24 mb-24 text-[24px] uppercase tracking-wider md:text-[32px] ">
								{" "}
								Zurück zur Aktion
							</Link>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default Confirm
