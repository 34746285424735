import * as React from "react"
import { motion, useInView, useMotionValueEvent, useScroll, useTransform } from "framer-motion"

interface HeroProps {
	type?: any
}

const Hero: React.FC<HeroProps> = ({ type = "" }) => {
	return (
		<>
			{type === "promoover" ? (
				<section className=" lg:bg-center-bottom top-0 z-10 mx-auto w-full bg-black bg-cover bg-center md:mb-2">
					<h1 className="mx-auto mt-12 mb-2 max-w-[1000px] pl-6 text-center font-Anton text-[33px] text-white md:pl-0 md:text-center md:text-[62px]">
						DIESE AKTION IST LEIDER BEENDET!
					</h1>
				</section>
			) : type === "newsletter" ? (
				<section className=" lg:bg-center-bottom top-0 z-10 mx-auto w-full bg-black bg-cover bg-center md:mb-24">
					<h1 className="mx-auto mt-12 mb-2 max-w-[700px] pl-6 font-Anton text-[33px] text-white md:pl-0 md:text-center md:text-[62px]">Vielen Dank</h1>
					<p className="mt-0 mb-6 max-w-[80%] pl-6 font-Nunito text-[18px] text-orange md:mx-auto md:max-w-[500px] md:px-0 md:text-center md:text-[32px]">
						Deine Teilnahme wurde bestätigt.
					</p>
					<p className="mt-6 mb-6 max-w-[80%] pl-6 font-Nunito text-[18px] text-orange md:mx-auto md:max-w-[500px] md:px-0 md:text-center md:text-[32px]">
						Viele Grüße
						<br />
						Dein Wilkinson Sword Team
					</p>
				</section>
			) : (
				<section className=" lg:bg-center-bottom top-0 z-10 mx-auto w-full bg-black bg-cover bg-center md:mb-24">
					<h1 className="mx-auto mt-12 mb-2 max-w-[700px] pl-6 font-Anton text-[33px] text-white md:pl-0 md:text-center md:text-[62px]">KAUFE EINEN WILKINSON 6</h1>
					<p className="mt-0 mb-6 max-w-[80%] pl-6 font-Nunito text-[18px] text-orange md:mx-auto md:max-w-[500px] md:px-0 md:text-center md:text-[32px]">
						RASIERER ODER KLINGEN UND HABE DIE CHANCE AUF
					</p>
				</section>
			)}

			{type === "promoover" ? (
				<section className="mt-12 text-center md:mt-0"></section>
			) : type === "newsletter" ? (
				<section className="mt-12 bg-orange md:mt-48"></section>
			) : (
				<section className="mt-12 bg-orange md:mt-48">
					<img src={process.env.PUBLIC_URL + "/images/volvic/hero-bg-desktop.png"} className="relative -top-12 mx-auto hidden max-w-[75%] md:block" />
					<img src={process.env.PUBLIC_URL + "/images/volvic/hero-bg-mobile.png"} className="relative mx-auto block pt-[40px] md:hidden" />
					<div className="relative -top-[99px]  ml-[9%] md:top-[75px] md:ml-0 md:text-center">
						<a href="#teilnehmen" className=" bg-white px-4 py-2 font-Anton text-[1.5rem] uppercase text-black md:mx-auto md:w-[140px] md:text-[2.5rem]">
							Jetzt Teilnehmen!*
						</a>
						<p className="relative top-2 w-[211px] py-2 text-left font-Nunito text-[0.5rem] uppercase text-white md:top-6 md:mx-auto md:w-[600px] md:px-4 md:text-center md:text-[1rem]">
							*Jeder Teilnehmer erhält einen 25%-Gutschein für den Wilkinson Online-Shop
						</p>
					</div>
				</section>
			)}
		</>
	)
}

export default Hero
