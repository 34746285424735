import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { useNavigate, Link } from "react-router-dom"
import RosaSection from "../components/elements/BeigeSection"
import Header from "../components/Header"

const Terms: React.FC = () => {
	const navigate = useNavigate()

	// SEO Meta Tags

	const meta = {
		// title: "Mit merci zum Muttertag filmreif Danke sagen!",
		// canonical: "https://arvato-hawaiian-tropic-2023.de/",
		// meta: {
		// name: {
		// robots: "noindex, nofollow",
		// },
		// },
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<div>
			<div className="light bg-black">
				<Header type="logo" />
				<div className="mx-auto  bg-black  py-8 px-4 text-white">
					<div className="mx-auto max-w-[800px]">
						<h2 className="h2 font-Veener mb-8 mt-12 text-center text-2xl text-orange lg:mt-12">Teilnahme&shy;bedingungen</h2>
						<h3 className="h3 py-4 tracking-wider">1. Veranstalter</h3>
						<p className="mb-4">
							Die Wilkinson Sword GmbH, Schützenstraße 110, D-42659 Solingen (nachfolgend "Veranstalter" oder "Wilkinson Sword" genannt) veranstaltet im Zeitraum vom 14.10.2024 bis
							zum 31.12.2024, 23:59 Uhr, eine zeitlich begrenzte Gewinnspiel-Aktion mit Gewinnspiel für die Marke Wilkinson Sword (die "Aktion"). Die Aktion wird von der Magellan
							GmbH, Oststraße 10, 40211 Düsseldorf, Deutschland und der Zum goldenen Hirschen Valley GmbH, Lichtstraße 43c, 50825 Köln, Deutschland durchgeführt.
						</p>

						<h3 className="h3 py-4 tracking-wider">2. Teilnahme</h3>
						<h3 className="pb-4 font-bold">A. Allgemeines</h3>
						<p className="mb-4">
							Teilnahmeberechtigt ist jede natürliche Person mit Wohnsitz in Deutschland, die zum Zeitpunkt der Teilnahme an der Aktion das achtzehnte (18.) Lebensjahr vollendet
							hat, unbeschränkt geschäftsfähig ist und einen qualifizierenden Einkauf wie unten beschrieben getätigt hat (nachfolgend Teilnehmer").
						</p>
						<p className="mb-4">
							Von der Teilnahme ausgeschlossen sind Mitarbeiter von Wilkinson Sword und der mit ihr verbundenen Unternehmen im Sinne von § 15 AktG und deren Angehörige sowie
							Mitarbeiter von Kooperationspartnern des Veranstalters, die an der Gestaltung oder Durchführung der Aktion beteiligt waren oder sind. Außerdem sind alle Mitarbeiter
							der Dirk Rossmann GmbH von der Teilnahme ausgeschlossen.
						</p>
						<p className="mb-4">
							Eine gewerbliche Teilnahme oder eine durch Dritte vermittelte Teilnahme (z.B. Gewinnspielservices, Makler oder Suchmaschinen) ist nicht zulässig. Darüber hinaus
							behält sich der Veranstalter das Recht vor, Personen von der Teilnahme auszuschließen, bei denen der Verdacht besteht, dass sie unerlaubte Hilfsmittel verwenden oder
							anderweitig versuchen, sich oder Dritten durch Manipulation einen Vorteil zu verschaffen (nach billigem Ermessen des Veranstalters). Der Veranstalter hat in solchen
							Fällen das Recht, die Auszahlung des Gewinns zu verweigern und/oder den Gewinn zurückzufordern.
						</p>
						<p className="mb-2">Für den Gewinn des ABT RS6-X 4.0 TFSI* gelten zusätzlich folgende Voraussetzungen: </p>
						<ul className="mb-4">
							<li>• zum Zeitpunkt der Teilnahme an der Aktion das dreiundzwanzigste (23.) Lebensjahr vollendet haben</li>
							<li>• seit mindestens drei Jahren ohne Unterbrechung in Besitz einer gültigen Fahrerlaubnis der Klasse B sind</li>
							<li>• Weniger als 3 Punkte im Flensburger Fahreignungsregister haben</li>
							<li>• keinen negativer Schufa-Score haben</li>
							<li>• nicht vorbestraft sind</li>
						</ul>
						<p className="mb-4 text-[10px]">
							*Kraftstoffverbrauch (kombiniert)2: 12,7–12,1 l/100 km; CO₂-Emissionen (kombiniert)2: 289–276 g/km; CO₂-Klasse2: G Die Verbrauchswerte beziehen sich auf das
							Serienfahrzeug.
						</p>
						<h3 className="pb-4 font-bold">B. Teilnahmevoraussetzungen und Ablauf</h3>
						<p className="mb-4">
							Um an der Aktion teilzunehmen, muss der Teilnehmer eines der Aktionsprodukte im angegebenen Einkaufszeitraum bei einem teilnehmenden Einzelhändler kaufen (auch
							Online-Käufe sind möglich).
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist vom 14.10.2024 bis zum 31.12.2024, 23:59 Uhr, durch Hochladen des Rechnungsbelegs und Eingabe der erforderlichen Daten auf der
							Aktionsseite unter{" "}
							<a href="https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel" target="_blank" className="underline">
								https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel
							</a>{" "}
							(die "Aktionsseite") möglich. Der Scan oder das Foto des Original-Rechnungsbelegs muss das teilnehmende Produkt, das teilnehmende Geschäft, den Preis, die
							Rechnungsbelegnummer und das Kaufdatum deutlich erkennen lassen.
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist kostenlos. Die Teilnehmer tragen lediglich ihre regulären Kosten für die von ihnen genutzten Kommunikationsmittel (z. B. Kosten für
							die Internetverbindung). Jeder Teilnehmer kann nur in seinem eigenen Namen teilnehmen. Eine Mehrfachteilnahme ist nicht möglich. Pro Teilnahme darf nur ein
							Rechnungsbeleg eingereicht werden. Jeder Rechnungsbeleg darf nur einmal im Rahmen der Aktion verwendet werden.
						</p>
						<p className="mb-4">
							Die Teilnahme an der Aktion ist nur über das Formular auf der Aktionsseite möglich. Um teilzunehmen, muss der Teilnehmer das Formular während des angegebenen
							Teilnahmezeitraums mit gültigen, erforderlichen Daten (Name, Alter, Kaufinformationen und E-Mail-Adresse) ausfüllen und bestätigen. Jeder Teilnehmer ist für die
							Richtigkeit seiner Daten selbst verantwortlich. Die Datenschutzbestimmungen und Teilnahmebedingungen müssen akzeptiert werden. Der Teilnehmer hat dafür Sorge zu
							tragen, dass durch seine Teilnahme keine Ansprüche wegen Verletzung der Privatsphäre oder des allgemeinen Persönlichkeitsrechts entstehen, keine Rechte und/oder
							Interessen Dritter verletzt werden und keine Zahlungsansprüche jeglicher Art entstehen.
						</p>
						<p className="mb-4">Eine Kombination mit anderen Gutscheinen oder Promotionen ist nicht möglich. Der Gewinnanspruch ist nicht auf Dritte übertragbar.</p>
						<p className="mb-4">Der Original-Rechnungsbeleg ist als Kaufnachweis bis nach der Verlosung aufzubewahren und auf Verlangen vorzulegen.</p>
						<h3 className="pb-4 font-bold">C. Kauf- und Teilnahmezeitraum</h3>
						<p className="mb-4">
							Der Kaufzeitraum für die Aktionsprodukte ist vom 14.10.2024 bis zum 31.12.2024, 23:59 Uhr (der "Kaufzeitraum"). Der Teilnahmezeitraum für den Rechnungseingangs-Upload
							ist vom 14.10.2024 bis zum 31.12.2024, 23:59 Uhr (der "Teilnahmezeitraum")..
						</p>
						<h3 className="pb-4 font-bold">D. Aktionsprodukte </h3>
						<p className="mb-4">
							Die Aktion gilt für alle folgenden Produkte (nachfolgend die "Produkte"), die zwischen dem 14.10.2024 und dem 31.12.2024, 23:59 Uhr bei einem teilnehmenden Händler in
							Deutschland oder online innerhalb Deutschlands ("Teilnehmender Händler") gekauft werden:
						</p>
						<p className="mb-2"> Teilnehmende Aktionsprodukte inkl. GTIN: </p>
						<ul className="mb-4">
							<li>• Wilkinson 6 Rasierapparat (GTIN: 4027800143702)</li>
							<li>• Wilkinson 6 Klinge 4er Packung (GTIN: 4027800143801)</li>
							<li>Teilnehmende Händler innerhalb Deutschlands:</li>
							<li>• dm-drogerie markt GmbH + Co. KG</li>
							<li>• Dirk Rossmann GmbH</li>
							<li>• Müller Handels GmbH & Co. KG</li>
							<li>• Amazon.de</li>
							<li>
								• Wilkinson Sword Online Shop:{" "}
								<a href="https://wilkinsonsword.de/" target="_blank" className="underline">
									https://wilkinsonsword.de/
								</a>
							</li>
						</ul>
						<h3 className="h3 py-4 tracking-wider">3. Durchführung der Werbeaktion</h3>
						<p className="mb-4">
							Am Ende der Aktion werden fünfundzwanzig (25) Gewinner nach dem Zufallsprinzip aus allen bis zum Ende der Aktion eingegangenen gültigen Einsendungen gezogen. Unter
							allen Teilnehmern wird ein (1) Jahr Fahrfreude im ABT RS6-X 4.0 TFSI*, sechs (6) 60€ Sky WOW Gutscheine, sechs (6) Sony PlayStation 5 F124 Spiele, sechs (6) Wilkinson
							Sword Merch Pakete (Wilkinson 6 Jacke, T-Shirt und Kappe), sechs (6) Jahresvorräte Wilkinson Sword Rasierklingen (12 Klingen + Rasierer) verlost. Außerdem gewinnt
							jeder Teilnehmer einen 25% Rabatt Code für die im Wilkinson Sword Online-Shop (Rasierer, Klingen & Pflegeprodukte von Wilkinson Sword – Wilkinson Sword Germany)
							erhältlichen Männerrasierer und Klingen. Diese werden nach dem Upload der Kassenbons an die angegebene Mailadresse versendet.
						</p>
						<p className="mb-4">
							Der Veranstalter ist nicht verantwortlich für die Vereinbarungen des Fahrzeugs, welches als Hauptpreis im Rahmen der Aktion angeboten wird. Der Prozess,
							einschließlich, aber nicht beschränkt auf die Verhandlungen, Vertragsbedingungen und Konditionen, erfolgt ausschließlich zwischen dem Gewinner und dem Unternehmen
							Scherer Gruppe. Der Veranstalter und die Aktion sind in keiner Weise mit der Scherer Gruppe und/oder dem Hersteller des Preises verbunden, assoziiert oder in
							irgendeiner Weise verknüpft.
						</p>
						<p className="mb-2">Der Gewinn besteht in der Überlassung eines ABT RS6-X 4.0 TFSI für die Dauer von einem Jahr zur Nutzung nach Maßgabe folgender Konditionen:</p>
						<ul className="mb-4">
							<li>• Die Überlassung des Fahrzeugs erfolgt durch Scherer Automobil Holding GmbH & Co. KG </li>
							<li>• Der Gewinner ist berechtigt, das Fahrzeug ein Jahr ab erfolgter Übergabe zu nutzen</li>
							<li>• Eingeschlossen ist eine Laufleistung von 10.000 Kilometer. Bei Überschreitung werden pro Mehrkilometer EUR 11,90 (inkl. MwSt.) erhoben. </li>
							<li>• Eingeschlossen ist die Versicherung des Fahrzeugs (Vollkasko mit EUR 2.500,00 Selbstbehalt) und die KfZ-Steuer. </li>
							<li>• Andere/zusätzliche Kosten (z. B. An- und Abreise zur Über- und Rückgabe, sonstige Verbrauchs- und Kraftstoffkosten) trägt der Gewinner. </li>
							<li>• Nur der Gewinner persönlich darf das Fahrzeug führen. Die Nutzung des Fahrzeugs durch oder dessen Überlassung an Dritte ist untersagt. </li>
							<li>
								• Die Nutzung des Fahrzeugs unter dem Einfluss von Alkohol (0,0 %o), Drogen oder Medikamenten, die das Fahrverhalten beeinflussen können, oder ohne die
								erforderliche Fahrerlaubnis oder während der Geltung eines gegen den Gewinner verhängten Fahrverbotes ist untersagt.{" "}
							</li>
							<li>
								• Auslandsfahrten (außerhalb des Gebiets der Bundesrepublik Deutschland), Renn- und Zuverlässigkeitsfahrten, Fahrertrainings oder ähnliche Nutzungen sowie die
								Nutzung des Fahrzeugs auf Rennstrecken (gleich ob Rennbetrieb oder Touristenfahrten) sind ausgeschlossen.{" "}
							</li>
							<li>
								• Für Schäden an dem Fahrzeug haftet der Gewinner, soweit diese nicht versichert sind. Er trägt insbesondere den Selbstbehalt im Schadensfall. Entsprechendes gilt,
								wenn die Versicherung aufgrund von Umständen, die dem Gewinner zuzurechnen sind, leistungsfrei wird (z. B. wegen grob fahrlässiger oder vorsätzlicher Herbeiführung
								des Versicherungsfalls oder Verletzung von Obliegenheiten nach dem Versicherungsvertrag).{" "}
							</li>
							<li>
								• Für die Bearbeitung von Verwarnungen, Strafzetteln oder sonstigen Verstößen, die im Zusammenhang mit der Nutzung unseres Angebots auftreten, wird eine
								Bearbeitungsgebühr in Höhe von 15,00 € pro Fall erhoben. Diese Gebühr deckt die Kosten für die administrative Abwicklung des Verstoßes und ist unabhängig von der
								Höhe des verhängten Bußgeldes oder der Art des Verstoßes.{" "}
							</li>
						</ul>
						<p className="mb-4">
							Für den Fall, dass ein Preis nicht verfügbar ist, behält sich der Veranstalter das Recht vor, nach billigem Ermessen einen gleich- oder höherwertigen Preis zu
							vergeben.
						</p>

						<h3 className="h3 py-4 tracking-wider">4. Auswahl der Gewinner, Benachrichtigung der Gewinner und Inanspruchnahme der Preise</h3>
						<p className="mb-4">
							Die Gewinner der Aktion werden innerhalb von 14 Tagen nach Ende des Teilnahmezeitraums per E-Mail benachrichtigt. Die Gewinner werden über die angegebenen
							Kontaktdaten kontaktiert und können aufgefordert werden, die Annahme des Preises zu bestätigen.
						</p>
						<p className="mb-4">
							Wenn ein Gewinner die Annahme des Preises nicht innerhalb von 14 Tagen bestätigt, verfällt der Preis, und ein anderer Gewinner wird nach dem Ermessen des
							Veranstalters aus dem ursprünglichen Pool der teilnahmeberechtigten Beiträge ausgewählt. Der Teilnehmer ist für die Richtigkeit der angegebenen Kontaktdaten
							verantwortlich. Kann der Gewinner aufgrund unvollständiger oder falscher Kontaktdaten nicht kontaktiert werden, verfällt der Preis, und ein anderer Gewinner wird
							gemäß den Bestimmungen dieser Klausel ausgewählt.
						</p>
						<p className="mb-2">Inanspruchnahme des Gewinns ABT RS6-X TFSI 4.0:</p>
						<ul className="mb-4">
							<li>
								• Das Fahrzeug wird spätestens 4 Wochen nach Ermittlung des Gewinners, nicht aber vor dem 02.01.2025, durch die Scherer Automobil Holding GmbH & Co. KGA („Scherer“)
								zur Übergabe bereitgestellt. Der Gewinner wird über die Bereitstellung per E-Mail unter der bei Registrierung angegebenen E-Mail-Adresse informiert. Er kann das
								Fahrzeug innerhalb von 2 Wochen nach Bereitstellung übernehmen. Hierzu vereinbart er einen Termin zu den üblichen Geschäftszeiten von Scherer zur Übergabe.{" "}
							</li>
							<li>• Ort der Übergabe sowie der Rückgabe des Fahrzeugs ist tbd.</li>
							<li>
								• Spätestens bis zur Übergabe des Fahrzeugs hat der Gewinner sich durch Vorlage eines amtlichen Ausweisdokument mit Lichtbild zu identifizieren und die zur Nutzung
								des Fahrzeugs erforderliche Fahrerlaubnis durch Vorlage seines Führerscheins nachzuweisen (jeweils im Original). Die Fahrerlaubnis ist ungeachtet vorheriger Vorlage
								bei Übergabe (ggf. erneut) durch Vorlage des Führerscheins im Original nachzuweisen. Weiter können der Veranstalter oder Scherer die Übergabe von der Übermittlung
								eines Auszugs aus dem Fahreignungsregister abhängig machen. Hierüber wird der Gewinner rechtzeitig vor Übergabe informiert.{" "}
							</li>
							<li>
								• Übernimmt der Gewinner das Fahrzeug nicht innerhalb von 14 Tagen nach Bereitstellung oder erfolgt die Übergabe des Fahrzeugs aus vom Gewinner zu vertretenden
								Gründen nicht (insbesondere wegen Nichterfüllung der Voraussetzungen unter 3. Durchführung der Werbeaktion), verfällt der Anspruch auf den Gewinn.
							</li>
						</ul>
						<p className="mb-4">
							Es ist nicht möglich, die Preise anders als festgelegt auszuzahlen, z. B. in bar, in Sachwerten, durch Umtausch oder Übertragung auf andere Personen. Ein Teilnehmer
							kann auf den Gewinn verzichten.
						</p>
						<h3 className="h3 py-4 tracking-wider">5. Beendigung der Aktion</h3>
						<p className="mb-4">
							Die Aktion kann jederzeit ohne Vorankündigung und ohne Angabe von Gründen geändert, verlängert, vorübergehend ausgesetzt oder vorzeitig beendet werden. Hieraus
							ergeben sich keine Ansprüche gegen den Veranstalter. Dies kann insbesondere dann der Fall sein, wenn Fehlfunktionen, Fälschungen, Viren oder ähnliche Schäden
							auftreten oder ein Fall von Missbrauch der Gewinnspiele vorliegt und die Verwaltung, Sicherheit, Fairness, Integrität oder der Ablauf der Aktion beeinträchtigt wird.
							Aus einer vorzeitigen Beendigung können keine Ansprüche abgeleitet werden.
						</p>
						<h3 className="h3 py-4 tracking-wider">6. Sonstiges</h3>
						<p className="mb-4">Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen Warenkauf.</p>
						<p className="mb-4">
							Sollten einzelne Bestimmungen der Teilnahmebedingungen unwirksam sein oder werden, so bleibt die Wirksamkeit der übrigen Teilnahmebedingungen unberührt. An ihre
							Stelle tritt diejenige gültige Bestimmung, die dem Zweck der unwirksamen Bestimmung am nächsten kommt.
						</p>
						<p className="mb-4">
							Wilkinson Sword behält sich das Recht vor, diese Teilnahmebedingungen jederzeit und ohne vorherige Ankündigung zu ändern. Dies gilt auch für die vorzeitige Beendigung
							der Aktion. Die Änderung tritt mit der Veröffentlichung der geänderten Teilnahmebedingungen auf{" "}
							<a href="https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel" target="_blank" className="underline">
								https://wilkinsonsword.de/pages/wilkinson-6-gewinnspiel
							</a>{" "}
							in Kraft. Die Teilnahmebedingungen sind für die Dauer der Aktion gültig. Es gilt ausschließlich das Recht der Bundesrepublik Deutschland. Alle Fragen oder Kommentare
							zur Aktion sind per E-Mail an info@wilkinson6-gewinnspiel.de zu richten.{" "}
						</p>
						<h3 className="h3 py-4 tracking-wider">7. Datenschutz</h3>
						<p className="mb-4">
							Die nachfolgenden Datenschutzhinweise informieren die Teilnehmer über Art, Umfang und Zweck der Erhebung und Verwendung ihrer personenbezogenen Daten im Rahmen der
							Aktion durch den Veranstalter als datenschutzrechtlich Verantwortlichem. Mit der Teilnahme an der Aktion und der Erteilung der Einwilligung willigt der Teilnehmer
							ein, dass Wilkinson Sword seine personenbezogenen Daten (Name, Geburtsdatum, E-Mail-Adresse) speichern und verarbeiten darf.
						</p>
						<p className="mb-4">Eine Weitergabe von Daten an Dritte erfolgt nur, wenn dies für die Abwicklung der Aktion erforderlich und gesetzlich zulässig ist.</p>
						<p className="mb-4">
							Im Rahmen der Aktion werden folgende personenbezogene Daten der Teilnehmer von der Magellan GmbH erhoben und verarbeitet und für die Teilnahme, Durchführung und
							Abwicklung der Gewinnspiele, einschließlich der Zustellung der Preise und ggf. der Veröffentlichung des Vornamens des Gewinners, nach Maßgabe dieser
							Teilnahmebedingungen und auf Grundlage einer Einwilligung verwendet.
						</p>
						<p className="mb-4">Die Verarbeitung der personenbezogenen Daten des Teilnehmers erfolgt zum Zweck der Durchführung und Abwicklung</p>
						<p className="mb-4">- der Aktion: Vor- und Nachname, Geburtsdatum, E-Mail-Adresse.</p>
						<p className="mb-4">
							Anschließend werden die oben genannten personenbezogenen Daten zu Werbezwecken verwendet, wenn der Teilnehmer dem Erhalt von Werbemitteilungen zugestimmt hat. Nach
							Beendigung der Aktion wird der Veranstalter die personenbezogenen Daten der Teilnehmer, die dem Erhalt von Werbeinformationen nicht zugestimmt haben, bis zum 30. März
							2025 löschen.
						</p>
						<p className="mb-4">
							Weitere Informationen darüber, wie der Veranstalter personenbezogene Daten verarbeitet, einschließlich Informationen über die Rechte der Teilnehmer, finden Sie unter:
							<a href="https://wilkinsonsword.de/policies/privacy-policy" target="_blank" className="underline">
								https://wilkinsonsword.de/policies/privacy-policy
							</a>
							. Die Teilnehmer haben das Recht, jederzeit Auskunft über die Verarbeitung ihrer personenbezogenen Daten zu erhalten, die Berichtigung, Löschung oder Einschränkung
							der Verarbeitung ihrer personenbezogenen Daten zu verlangen und die zur Verfügung gestellten personenbezogenen Daten in einem strukturierten, allgemein gebräuchlichen
							und maschinenlesbaren Format zu erhalten und an einen anderen für die Verarbeitung Verantwortlichen zu übermitteln. Die Teilnehmer haben das Recht, ihre Einwilligung
							zu widerrufen, wenn der Veranstalter personenbezogene Daten auf der Grundlage ihrer Einwilligung verarbeitet. Zu diesem Zweck können sich die Teilnehmer an die
							folgende Adresse wenden: Wilkinson Sword GmbH, Schützenstraße 110, 42659 Solingen. Die Bitte um Auskunft und Widerruf kann der Teilnehmer per E-Mail an den
							Veranstalter unter info@wilkinson6-gewinnspiel.de richten. Im Übrigen gelten die allgemeinen Datenschutzrichtlinien des Veranstalters.
						</p>
						<h3 className="h3 py-4 tracking-wider">8. Haftung</h3>
						<p className="mb-4">
							Der Veranstalter haftet für eigenes Verschulden und grobe Fahrlässigkeit, sowie das seiner gesetzlichen Vertreter, leitenden Angestellten oder sonstigen
							Erfüllungsgehilfen nach den gesetzlichen Bestimmungen. Gleiches gilt für die Übernahme von Garantien oder sonstiger verschuldensunabhängiger Haftung sowie für
							Ansprüche nach dem Produkthaftungsgesetz oder bei schuldhafter Verletzung von Leben, Körper oder Gesundheit.
						</p>
						<p className="mb-4">
							Der Veranstalter haftet für die eigene, die seiner Vertreter, leitenden Angestellten und einfachen Erfüllungsgehilfen einfach fahrlässige Verletzung wesentlicher
							Vertragspflichten, d.h. solcher Pflichten, auf deren ordnungsgemäße Erfüllung die Teilnehmer bei der Durchführung der Aktion regelmäßig vertrauen dürfen; in diesem
							Fall ist die Haftung der Höhe nach auf den typischerweise vorhersehbaren Schaden begrenzt.
						</p>
						<p className="mb-4">Jede weitere Haftung des Veranstalters ist ausgeschlossen.</p>
						<h3 className="h3 py-4 tracking-wider">9. Einverständniserklärung </h3>
						<p className="mb-4">Der Teilnehmer erklärt sich einverstanden, dass sein Name im Falle eines Gewinns öffentlich bekanntgegeben wird.</p>
						<p className="mb-4">
							Weiter erklärt sich der Teilnehmer damit einverstanden, dass bei der Übergabe des Gewinns Foto- und Videoaufnahmen durch den Veranstalter, Scherer Automobil Holding
							GmbH & Co. KGA, ABT Lifestyle GmbH, Zum goldenen Hirschen Valley GmbH oder einen von diesen beauftragten Fotografen gemacht werden dürfen, auf denen der Teilnehmer
							erkennbar ist, und dass diese Aufnahmen vom Veranstalter, Scherer Automobil Holding GmbH & Co. KGA, ABT Lifestyle GmbH und Zum goldenen Hirschen Valley GmbH zu
							Werbezwecken genutzt werden. Insbesondere umfasst diese Genehmigung die Veröffentlichung auf den Internetseiten sowie den Social Media-Profilen des Veranstalters,
							Scherer Automobil Holding GmbH & Co. KGA, ABT Lifestyle GmbH und Zum goldenen Hirschen Valley GmbH.
						</p>
					</div>
					<div className=" py-10 pt-5 text-center text-xl text-white">
						<Link to="/" className="btn btn-hero mt-8 rounded bg-white px-12 py-4 text-white">
							Zurück
						</Link>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Terms
