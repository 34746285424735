import React, { useEffect } from "react"
import Header from "../components/Header"
import PreHero from "./PreHero"
import { Link } from "react-router-dom"
import Hero from "../components/home/Hero"
import Info from "../components/Info"

const Confirm: React.FC = () => {
	function track(label: string) {
		try {
			// eslint-disable-next-line
			let dataLayer = window["dataLayer"] || []
			dataLayer.push({ event: "footer", link: label })
		} catch (e) {}
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<>
			<div className="light bg-black">
				<Header />
				<Hero type="promoover" />
				<div className="mx-auto  mb-12 max-w-[900px] text-center lg:mt-6 lg:mb-2">
					<p className="mb-4 px-4 text-[18px] text-white md:text-[22px] ">
						Leider ist der Teilnahmezeitraum abgelaufen. <br />
						Um keine Aktion mehr zu verpassen, besuche uns auf wilkinson.de und folge uns auf Instagram oder TikTok.
					</p>
				</div>
				<div className="mb-12 pb-12 text-center lg:mt-24 lg:pb-24">
					<a href="https://wilkinsonsword.de/" className="btn mx-auto mt-24 mb-24 font-Anton text-[24px] uppercase text-white md:text-[42px]">
						{" "}
						zu Wilkinson
					</a>
				</div>
			</div>
		</>
	)
}
export default Confirm
