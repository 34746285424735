import React from "react"
import RedSection from "../elements/RedSection"
import { useAutoAnimate } from "@formkit/auto-animate/react"
import { useContext, useState } from "react"
import { useNavigate, Link } from "react-router-dom"
import { ERRORTYPES, MAX_PRODUCT_PRICE } from "../../config"
import { Context } from "../../store/store"
import Alert from "../elements/Alert"
import Spinner from "../elements/Spinner"
import Legal from "./Legal"
import { placeOrder } from "./OrderFct"
import Person from "./Person"
import PreFillForm from "./PreFillForm"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import Product from "./Product"
import BankAccount from "./BankAccount"
import Receipt from "../receipt/Receipt"
import Mafo from "./Mafo"
import { globalAgent } from "http"

const Form = () => {
	const { globalState, dispatch } = useContext(Context)
	const [validation, setValidation] = useState({})
	const navigate = useNavigate()
	const [formError, setFormError] = useState<ERRORTYPES | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [animationRef] = useAutoAnimate<HTMLDivElement>()

	function resetQuestion(key) {
		dispatch({
			type: "SET_ORDER_ENTRY",
			key: key,
			value: "",
		}) // strip HTML tags
	}
	function formElementChanged(e, key) {
		// special handling for checkboxe
		if (key === "product") {
			if (e.target.checked) {
				dispatch({
					type: "SET_ORDER_ENTRY",
					key: key,
					value: e.target
						.getAttribute("data-value")
						.replace(/(<([^>]+)>)/gi, "")
						.replace("https://www", "")
						.replace("mailto:", ""),
				}) // strip HTML tags
			}
		} else {
			if (e.target.type === "checkbox") {
				e.target.value = e.target.checked
			}
			dispatch({
				type: "SET_ORDER_ENTRY",
				key: key,
				value: e.target.value
					.replace(/(<([^>]+)>)/gi, "")
					.replace("https://www", "")
					.replace("mailto:", ""),
			}) // strip HTML tags
		}

		let isFieldInvalid = false

		const doc: any = document
		const elem = doc.querySelector("[name='" + key + "']:valid")
		if (elem === null) {
			isFieldInvalid = true
		}

		// check for price maximum
		if (key === "purchaseAmount") {
			const price = parseFloat(e.target.value.replace(",", "."))
			if (price > MAX_PRODUCT_PRICE) {
				isFieldInvalid = true
			}
		}
		// check validation
		setValidation({ ...validation, ["invalid_" + key]: isFieldInvalid })
	}

	function submitOrder() {
		// create order part
		const orderSuccessCallback = extId => {
			setIsLoading(false)
			if (extId) {
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "particpate", "form-submit": false, field_error: "send" })
				} catch (e) {}

				navigate("/danke")
			} else {
				setFormError(ERRORTYPES.SERVER)
				try {
					// eslint-disable-next-line
					let dataLayer = window["dataLayer"] || []
					dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
				} catch (e) {}
			}
		}
		const orderErrCallback = (tooManyAttemps: false, invalidCaptcha: false, message) => {
			setIsLoading(false)
			//TODO: Handling needs to be switched to error codes (BE part still open)
			if (tooManyAttemps) {
				setFormError(ERRORTYPES.MAXTRIES)
			} else if (invalidCaptcha) {
				setValidation({ ...validation, invalid_captcha: true })
				setFormError(null)
			} else if (message === "question1") {
				setFormError(ERRORTYPES.WRONGQUESTION1)
			} else if (message === "question2") {
				setFormError(ERRORTYPES.WRONGQUESTION2)
			} else {
				console.log(message)
				setFormError(ERRORTYPES.SERVER)
			}
			try {
				// eslint-disable-next-line
				let dataLayer = window["dataLayer"] || []
				dataLayer.push({ event: "form-submit", "form-name": "participate", "form-submit": true, package: globalState.package })
			} catch (e) {}
		}

		if (allInputFieldsValid()) {
			if (globalState.receiptReference) {
				setIsLoading(true)
				setFormError(null)
				placeOrder(globalState, setFormValidation, orderSuccessCallback, orderErrCallback)
			} else {
				setFormError(ERRORTYPES.UPLOADSMISSING)
			}
		} else {
			setFormError(ERRORTYPES.FORMVALIDATION)
			scrollToError()
		}
	}

	function isBicRequired() {
		return globalState.iban && globalState.iban.length > 2 && globalState.iban.substring(0, 2).toUpperCase() !== "DE"
	}

	function allInputFieldsValid() {
		// input names has to fit to variable classNames
		let isAllValid = true
		let invalidFields = {}

		// Special handing for bic. Bic is only for non DE IBANs required
		let fieldsToCheck: any = []
		if (isBicRequired()) {
			fieldsToCheck = Object.keys(globalState).filter(
				e =>
					e !== "package" &&
					e !== "captcha" &&
					e !== "captchaIdent" &&
					e !== "receiptReference" &&
					e !== "receiptFileName" &&
					e !== "shop" &&
					e !== "privacy" &&
					e !== "price" &&
					e !== "bic" &&
					e !== "question1Other" &&
					e !== "question2Other"
			)
		} else {
			fieldsToCheck = Object.keys(globalState).filter(
				e => e !== "package" && e !== "captcha" && e !== "captchaIdent" && e !== "receiptReference" && e !== "receiptFileName" && e !== "salutation"
			)
		}

		for (let index = 0; index < fieldsToCheck.length; index++) {
			const key = fieldsToCheck[index]

			const doc: any = document
			const elem = doc.querySelector("[name='" + key + "']:valid")
			console.log(elem)
			if (elem === null) {
				isAllValid = false
				console.log(key)
				invalidFields["invalid_" + key] = true
			}
		}
		console.log(invalidFields)
		if (Object.keys(invalidFields).length) {
			setValidation({ ...validation, ...invalidFields })
		}

		return isAllValid
	}

	function setFormValidation(data: {}) {
		setValidation({
			...validation,
			...data,
		})
		scrollToError()
	}

	function scrollToError() {
		setTimeout(() => {
			try {
				const doc: any = document
				const { top } = doc.querySelector(".js-formerror").getBoundingClientRect()

				window.scrollTo({
					top: top + window.scrollY - 120,
					left: 0,
					behavior: "smooth",
				})
			} catch (error) {}
		}, 500)
	}

	return (
		<RedSection>
			<form
				onSubmit={e => {
					e.preventDefault()
					submitOrder()
				}}
				noValidate={true}
				className=""
			>
				<div className="grid grid-cols-1 ">
					<div>
						<div className="after-border relative bg-black pb-0 lg:p-0">
							<div className="bg-norepeat  relative mx-auto  bg-contain bg-center bg-repeat-y">
								<div className="relative z-0 mx-auto -mt-[1px] grid  grid-cols-1 flex-col items-center">
									<div className="upload sogehts items-center bg-orange py-2 px-4 lg:py-12">
										<div className="mx-auto max-w-[1400px]" id="teilnehmen">
											<h2 className="mt-6 mb-4 text-center font-Anton text-[36px] uppercase tracking-wider text-white lg:text-[58px]">JETZT TEILNEHMEN!</h2>
											<p className="mx-auto text-center text-[18px] font-[500] leading-[22px] text-white lg:max-w-[100%] lg:text-[22px] lg:leading-[37px]">
												Formular ausfüllen, Foto des Kassenbons hochladen und auf "Jetzt teilnehmen" klicken.
											</p>
										</div>
									</div>
								</div>
								<GoogleReCaptchaProvider language="de" useRecaptchaNet={true} reCaptchaKey="6LeH20gqAAAAAIWXtS2BkZ3tTkARCXul8YNrx7Js">
									<div className="bg-orange pb-6 md:pb-24">
										<div className="mx-auto max-w-[1400px]">
											<Person {...{ validation, formElementChanged }} />
										</div>
									</div>
									<div className="mx-auto max-w-[1400px]">
										<Receipt />
										<Legal {...{ validation, formElementChanged }} />
									</div>
								</GoogleReCaptchaProvider>
							</div>
						</div>

						<div className="bg-black pt-8 pb-2" ref={animationRef}>
							<div className="mb-12">
								<div className="relative px-4 text-center">
									{formError && <Alert className={"my-4 font-bold text-errorred"}>{formError}</Alert>}
									<div className="relative inline-block">
										<button
											type="submit"
											className="mb-6 block w-[100%] bg-orange p-4 font-Anton text-[24px] font-bold uppercase tracking-wider text-white drop-shadow-md sm:w-[auto] md:text-[32px]  lg:inline-block lg:py-2 lg:px-6"
											disabled={isLoading ? true : undefined}
										>
											{isLoading && <Spinner />}
											jetzt teilnehmen!
										</button>
									</div>
									<p className="text-[12px] text-white md:text-[14px]">
										Informationen zum Datenschutz findest du{" "}
										<a href={"https://wilkinsonsword.de/policies/privacy-policy"} target="_blank" className="text-white underline">
											hier
										</a>
										.
									</p>
									<p className="before-border mx-auto mt-3 max-w-[450px] text-center text-[12px] leading-[50px] text-white text-white md:mb-6 md:text-[14px]  ">* Pflichtfelder</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</RedSection>
	)
}

export default Form
